<!-- eslint-disable space-before-blocks -->
<template>
    <el-dialog
        title="Phân công TKQC"
        :visible.sync="visible_inline"
        width="840px"
    >
        <div slot="title">
            <div
                v-if="account"
                class="text-left truncate w-4/5 text-base font-semibold"
            >
                {{ $t('common.delegate_permissions_for_second_account') }} ({{
                    account.username
                }})
            </div>
        </div>

        <el-table v-loading="m__loading" :max-height="440" :data="list_permission_users">
            <el-table-column
                width="180"
                :show-overflow-tooltip="true"
                prop="advertiser_id"
                label="ID"
            />
            <el-table-column
                width="220"
                :show-overflow-tooltip="true"
                prop="name"
                :label="$t('table.header.username')"
            />
            <el-table-column :label="$t('common.permission')">
                <el-table-column
                    prop="state"
                    :label="$t('common.report_ads')"
                    min-width="120"
                >
                    <template #default="scope">
                        <el-checkbox
                            v-model="scope.row.report_ads"
                        ></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="state"
                    :label="$t('common.manager_ads')"
                    min-width="120"
                >
                    <template #default="scope">
                        <el-checkbox
                            v-model="scope.row.manager_ads"
                        ></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="state"
                    :label="$t('common.auto_ads')"
                    min-width="120"
                >
                    <template #default="scope">
                        <el-checkbox v-model="scope.row.auto_ads"></el-checkbox>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>

        <div class="mt-6 flex justify-end">
            <el-button size="small" @click="visible_inline = false">
                {{ $t('button.close') }}
            </el-button>
            <el-button
                size="small"
                type="primary"
                icon="el-icon-menu"
                @click="updateUserRole()"
            >
                {{ $t('common.permission') }}</el-button>
        </div>
    </el-dialog>
</template>

<script>
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import { updateUserRole } from '@/services/atosa-tiktok-ads/user-role'

export default {
    mixins: [selectedTiktokBusinessMixin],

    props: ['visible', 'account'],

    data() {
        return {
            list_permission_users: []
        }
    },

    computed: {
        visible_inline: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        },

        tiktok_account_id() {
            if (this.m__selected_tiktok_business) {
                return this.m__selected_tiktok_business.id
            }
            return ''
        }
    },

    watch: {
        tiktok_account_id() {
            this.fetchAdsAccountsByTiktokBusiness()
        },

        visible_inline() {
            this.loadListPermissionUsers()
        }
    },

    mounted() {
        this.fetchAdsAccountsByTiktokBusiness()
    },

    methods: {
        async fetchAdsAccountsByTiktokBusiness() {
            if (
                !this.m__selected_tiktok_business ||
                !this.m__selected_tiktok_business.id ||
                this.m__ads_accounts.length > 0
            ) {
                return
            }

            await this.m__fetchAdsAccountsByTiktokBusiness()

            this.loadListPermissionUsers()
        },

        loadListPermissionUsers() {
            if (!this.m__ads_accounts.length || !this.account) {
                return
            }

            this.list_permission_users = this.m__ads_accounts.map((item) => {
                let report_ads = false
                let manager_ads = false
                let auto_ads = false

                const temp = this.account?.role?.advertiser_list.find(
                    (it) => it.advertiser_id === item.advertiser_id
                )

                if (temp) {
                    report_ads = temp.permissions.some(
                        (it) => it === 'REPORT_ADS'
                    )
                    manager_ads = temp.permissions.some(
                        (it) => it === 'MANAGER_ADS'
                    )
                    auto_ads = temp.permissions.some((it) => it === 'AUTO_ADS')
                }

                return {
                    ...item,
                    report_ads,
                    manager_ads,
                    auto_ads
                }
            })
        },

        async updateUserRole() {
            try {
                const advertiser_list = this.list_permission_users
                    .filter(
                        (item) =>
                            item.report_ads || item.manager_ads || item.auto_ads
                    )
                    .map((item) => {
                        const permissions = []

                        if (item.report_ads) {
                            permissions.push('REPORT_ADS')
                        }

                        if (item.manager_ads) {
                            permissions.push('MANAGER_ADS')
                        }

                        if (item.auto_ads) {
                            permissions.push('AUTO_ADS')
                        }

                        return {
                            advertiser_id: item.advertiser_id,
                            advertiser_name: item.name,
                            tiktok_account: {
                                _id: this.m__selected_tiktok_business.id,
                                user_name:
                                    this.m__selected_tiktok_business.user_name
                            },
                            permissions
                        }
                    })

                await updateUserRole(this.m__selected_tiktok_business.id, {
                    username: this.account.username,
                    advertiser_list
                })

                this.p__showNotify(
                    'success',
                    this.$t('common.delegate_permission_success')
                )

                this.p__event_bus.$emit('eb_reload_list_user_roles')

                this.visible_inline = false
            } catch (error) {
                this.p__showNotify(
                    'warn',
                    this.$t('common.delegate_permission_error')
                )
                console.error(error)
            }
        }
    }
}
</script>
