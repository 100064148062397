<template>
    <el-dialog
        title="Cập nhật tài khoản phụ"
        :visible.sync="visible_inline"
        width="480px"
    >
        <div slot="title">
            <div class="text-left truncate w-4/5 text-base font-semibold">
                {{ $t('common.edit_second_account') }}
            </div>
        </div>

        <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
            <el-form-item :label="$t('common.username')" prop="username">
                <el-input
                    v-model="ruleForm.username"
                    size="small"
                    disabled
                    :placeholder="$t('common.username')"
                />
            </el-form-item>

            <el-form-item
                :label="$t('common.name')"
                class="mt-4"
                prop="display_name"
            >
                <el-input
                    v-model="ruleForm.display_name"
                    size="small"
                    :placeholder="$t('common.name')"
                />
            </el-form-item>

            <el-form-item class="mt-4" label="Email" prop="email">
                <el-input
                    v-model="ruleForm.email"
                    size="small"
                    placeholder="Email"
                />
            </el-form-item>

            <el-form-item
                class="mt-4"
                :label="$t('page.login.placeholder.phone')"
                prop="phone"
            >
                <el-input
                    v-model="ruleForm.phone"
                    size="small"
                    :placeholder="$t('page.login.placeholder.phone')"
                />
            </el-form-item>
        </el-form>

        <div class="mt-4 flex justify-end">
            <el-button size="small" @click="visible_inline = false">
                {{ $t('button.close') }}
            </el-button>
            <el-button
                size="small"
                type="primary"
                icon="el-icon-edit"
                @click="submitForm('ruleForm')"
            >
                {{ $t('common.edit') }}
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import { editAccount } from '@/services/atosa/account'

export default {
    props: ['visible', 'account'],

    data() {
        const validateVNPhoneNumber = (rule, value, callback) => {
            const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g

            if (value.match(regexPhoneNumber)) {
                callback()
            } else {
                callback(new Error('Không đúng định dạng SĐT'))
            }
        }
        return {
            ruleForm: {
                display_name: '',
                username: '',
                email: '',
                phone: '',
                role: 'staff',
                blocked: false,
                selected_shop_id: ''
            },
            rules: {
                display_name: [
                    {
                        required: true,
                        message: 'Hãy điền thông tin',
                        trigger: 'blur'
                    },
                    {
                        min: 6,
                        max: 32,
                        message: 'Độ dài dài từ 6 đến 36 kí tự',
                        trigger: 'blur'
                    }
                ],
                email: [
                    {
                        required: true,
                        message: 'Hãy điền thông tin',
                        trigger: 'blur'
                    },
                    {
                        type: 'email',
                        message: 'Xin hãy nhập định dạng email',
                        trigger: ['blur', 'change']
                    }
                ],
                phone: [
                    {
                        required: true,
                        message: 'Hãy điền thông tin',
                        trigger: 'blur'
                    },
                    { validator: validateVNPhoneNumber, trigger: 'blur' }
                ]
            }
        }
    },

    computed: {
        visible_inline: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        // whenever question changes, this function will run
        account(newAcc) {
            if (newAcc) {
                this.ruleForm.display_name = newAcc.display_name
                this.ruleForm.username = newAcc.username
                this.ruleForm.email = newAcc.email
                this.ruleForm.phone = newAcc.phone
                this.ruleForm.blocked = newAcc.blocked
                this.ruleForm.more_info = newAcc.more_info
            }
        }
    },

    methods: {
        async edit() {
            try {
                const response = await editAccount(
                    this.account._id,
                    this.ruleForm
                )

                if (response.status_code === 200) {
                    this.p__showNotify(
                        'success',
                        this.$t('common.update_data_success')
                    )

                    this.p__event_bus.$emit('eb_reload_list_user_roles')

                    this.visible_inline = false
                }
            } catch (error) {
                console.error(error)
            }
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.edit()
                } else {
                    console.error('error submit!!')
                    return false
                }
            })
        },

        resetForm(formName) {
            this.$refs[formName]?.resetFields()
        }
    }
}
</script>
