import request from './_config'

export function createAccount(data) {
    return request({
        url: 'store/user',
        method: 'post',
        data: {
            username: data.username,
            display_name: data.display_name,
            email: data.email,
            phone: data.phone,
            password: data.password,
            group: data.group,
            role: data.role
        }
    })
}

export function editAccount(user_id, data) {
    return request({
        url: `store/user/${user_id}`,
        method: 'put',
        data: {
            display_name: data.display_name,
            email: data.email,
            phone: data.phone,
            // group: data.group,
            blocked: data.blocked,
            more_info: data.more_info
        }
    })
}

export function getAccounts() {
    return request({
        url: 'store/user',
        method: 'get'
    })
}
