<template>
    <div class="component-box">
        <div class="flex items-center justify-between mb-4">
            <p class="font-semibold">{{ $t('common.second_account') }}</p>

            <el-button
                v-if="
                    current_package &&
                    current_package.extra_data.permission_second_account
                "
                size="small"
                icon="el-icon-plus"
                type="primary"
                @click="show_create_account_dialog = true"
                >{{ $t('button.create') }}
            </el-button>

            <div v-else>
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('common.feature_must_buy')"
                    placement="top"
                >
                    <el-button
                        class="cursor-not-allowed opacity-60"
                        size="small"
                        icon="el-icon-plus"
                        type="primary"
                        >{{ $t('button.create') }}
                    </el-button>
                </el-tooltip>
            </div>
        </div>

        <el-alert
            :title="$t('common.desc_permission')"
            type="success"
            class="mb-2"
            :closable="false"
        >
        </el-alert>

        <el-table v-loading="m__loading" :data="list_user_roles">
            <el-table-column label="#" type="index" width="60" />

            <el-table-column
                width="200"
                :show-overflow-tooltip="true"
                prop="username"
                :label="$t('table.header.username')"
            />

            <el-table-column width="120" :label="$t('common.status')">
                <template #default="scope">
                    <el-switch
                        :value="!scope.row.blocked"
                        @change="handleChangeBlocked(scope.row)"
                    />
                </template>
            </el-table-column>

            <el-table-column
                width="200"
                :show-overflow-tooltip="true"
                prop="display_name"
                :label="$t('common.name')"
            />

            <el-table-column
                width="160"
                :show-overflow-tooltip="true"
                prop="phone"
                :label="$t('page.login.placeholder.phone')"
            />

            <el-table-column
                width="200"
                :show-overflow-tooltip="true"
                prop="email"
                label="Email"
            />

            <el-table-column
                min-width="200"
                :show-overflow-tooltip="true"
                prop="last_access_time"
                :label="$t('common.last_access_time')"
            >
                <template #default="scope">
                    <p>
                        {{ p__formatDate(scope.row.last_access_time) }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                fixed="right"
                width="320"
                :label="$t('common.option')"
            >
                <template #default="scope">
                    <div class="flex space-x-2">
                        <el-button
                            size="small"
                            icon="el-icon-edit"
                            @click="showEditDialog(scope.row)"
                        >
                            {{ $t('common.edit') }}
                        </el-button>
                        <el-button
                            size="small"
                            type="warning"
                            icon="el-icon-menu"
                            @click="showAssignmentDialog(scope.row)"
                        >
                            {{ $t('common.permission') }}
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <CreateAccountDialog
            :visible.sync="show_create_account_dialog"
        ></CreateAccountDialog>

        <EditAccountDialog
            :visible.sync="show_edit_dialog"
            :account="selected_account"
        ></EditAccountDialog>

        <AssignmentDialog
            :visible.sync="show_assignment_dialog"
            :account="selected_account"
        ></AssignmentDialog>
    </div>
</template>

<script>
import { getUserRoleList } from '@/services/atosa-tiktok-ads/user-role'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import { editAccount } from '@/services/atosa/account'
import CreateAccountDialog from './create-account-dialog'
import EditAccountDialog from './edit-account-dialog'
import AssignmentDialog from './assignment-dialog'

export default {
    components: {
        CreateAccountDialog,
        AssignmentDialog,
        EditAccountDialog
    },

    mixins: [selectedTiktokBusinessMixin],

    data() {
        return {
            show_create_account_dialog: false,
            list_user_roles: [],
            show_edit_dialog: false,
            show_assignment_dialog: false,
            selected_account: null
        }
    },

    computed: {
        current_package() {
            return this.$store.getters.currentPackage.s__current_package
        }
    },

    watch: {
        m__selected_tiktok_business() {
            this.getUserRoleList()
        }
    },

    mounted() {
        this.getUserRoleList()

        this.p__event_bus.$on('eb_reload_list_user_roles', this.getUserRoleList)
    },

    destroyed() {
        this.p__event_bus.$off(
            'eb_reload_list_user_roles',
            this.getUserRoleList
        )
    },

    methods: {
        async getUserRoleList() {
            if (
                !this.m__selected_tiktok_business ||
                !this.m__selected_tiktok_business.id
            ) {
                return
            }

            this.m__loading = true

            try {
                const response = await getUserRoleList(
                    this.m__selected_tiktok_business.id
                )

                if (response.code === 0) {
                    this.list_user_roles = response.data.filter(
                        (item) => !item.is_manager
                    )
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        showEditDialog(account) {
            this.selected_account = account
            this.show_edit_dialog = true
        },

        showAssignmentDialog(account) {
            this.selected_account = account
            this.show_assignment_dialog = true
        },

        async handleChangeBlocked(account) {
            const response = await editAccount(account._id, {
                ...account,
                blocked: !account.blocked
            })

            if (response.status_code === 200) {
                this.getUserRoleList()
            }
        }
    }
}
</script>
